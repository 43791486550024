<template>
    <div class="border-bottom pb-2 mb-3">
        <div class="d-flex">
            <div class="flex-grow-1">
                <p class="fw-bold">Author</p>
            </div>
            <div>
                <button
                        type="button"
                        class="btn btn-sm btn-purple rounded-pill"
                        v-if="
            data.canEdit &&
            ((data.status === 'Assigned' && data.show.includes('author')) ||
              data.author.lists.length == 0)
          "
                        @click.prevent="
            approverGroupUpdate('user_invite', {
              category,
              id,
              role: author.id,
              roleName: author.role_name,
              type: 'Author',
              compName,
              project_id,
            })
          "
                >
                    <font-awesome-icon :icon="['fas', 'user-plus']"></font-awesome-icon>
                    <span>Add</span>
                </button>
            </div>
        </div>
        <div v-if="data.author.lists.length">
            <div
                    v-for="(au, a) in data.author.lists"
                    :key="a"
                    class="d-flex align-items-center"
            >
                <div class="profile-avatar me-2">
                    <img
                            v-if="au.photo != null && au.photo.length > 1"
                            :src="require('@/assets/img/profile-avatar.png')"
                            :alt="au.name.toUpperCase()"
                            class="img-fluid"
                    />
                    <span v-else class="white--text headline">{{
                            au.photo != null ? au.photo.toUpperCase() : au.name[0].toUpperCase()
                        }}</span>
                </div>
                <p>{{ au.name.toUpperCase() }}</p>
            </div>
        </div>
    </div>
    <div class="border-bottom pb-2 mb-3">
        <div class="d-flex">
            <div class="flex-grow-1">
                <p class="fw-bold">Reviewer</p>
            </div>
            <div>
                <button
                        v-if="
            data.canEdit &&
            ((data.status === 'Assigned' && data.show.includes('review')) ||
              data.reviewer.lists.length == 0)
          "
                        type="button"
                        class="btn btn-purple rounded-pill"
                        @click.prevent="
            approverGroupUpdate('user_invite', {
              category,
              id,
              role: reviewer.id,
              roleName: reviewer.role_name,
              type: 'Review',
              compName,
              project_id,
            })
          "
                >
                    <font-awesome-icon :icon="['fas', 'user-plus']"></font-awesome-icon>
                    <span>Add</span>
                </button>
            </div>
        </div>
        <div v-if="data.reviewer.lists.length">
            <div
                    v-for="(rev, r) in reviewer.lists"
                    :key="r"
                    class="d-flex align-items-center"
            >
                <div class="profile-avatar me-2">
                    <img
                            v-if="rev.photo != null && rev.photo.length > 1"
                            :src="rev.photo"
                            :alt="rev.name ? rev.name.toUpperCase() : rev.email.toUpperCase()"
                            class="img-fluid"
                    />
                    <span v-else class="white--text headline">{{
                            rev.photo != null
                                ? rev.photo.toUpperCase()
                                : rev.name
                                    ? rev.name[0].toUpperCase()
                                    : rev.email[0].toUpperCase()
                        }}</span>
                </div>
                <p>{{ rev.name ? rev.name.toUpperCase() : rev.email.toUpperCase() }}</p>
                <a
                        type="button"
                        v-if="data.status === 'Assigned' && data.canEdit"
                        class="flex-shrink-0 ms-1"
                        @click.prevent="deleteUser('Review', rev, r)"
                >
                    <font-awesome-icon :icon="['fas', `trash-alt`]"></font-awesome-icon>
                </a>
                <a
                        type="button"
                        v-if="
            data.status === 'Assigned' &&
            data.canEdit &&
            (rev.status == 'Invited' || rev.status == 'Re Invited')
          "
                        class="flex-shrink-0 ms-1"
                        @click.prevent="resendInvitation('reviewer', rev, r)"
                >
                    <font-awesome-icon
                            :icon="['fas', `envelope-open`]"
                    ></font-awesome-icon>
                </a>
            </div>
        </div>
    </div>
    <div class="border-bottom pb-2 mb-3">
        <div class="d-flex">
            <div class="flex-grow-1">
                <p class="fw-bold">Approver</p>
            </div>
            <div>
                <button
                        v-if="
            data.canEdit &&
            ((data.status === 'Assigned' && data.show.includes('approve')) ||
              data.approver.lists.length == 0)
          "
                        type="button"
                        class="btn btn-purple rounded-pill"
                        @click.prevent="
            approverGroupUpdate('user_invite', {
              category,
              id,
              role: data.approver.id,
              roleName: data.approver.role_name,
              type: 'Approve',
              compName,
              project_id,
            })
          "
                >
                    <font-awesome-icon :icon="['fas', 'user-plus']"></font-awesome-icon>
                    <span>Add</span>
                </button>
            </div>
        </div>
        <div v-if="data.approver.lists.length">
            <div
                    v-for="(app, a) in approver.lists"
                    :key="a"
                    class="d-flex align-items-center"
            >
                <div class="profile-avatar me-2">
                    <img
                            v-if="app.photo != null && app.photo.length > 1"
                            :src="app.photo"
                            :alt="app.name ? app.name.toUpperCase() : app.email.toUpperCase()"
                            class="img-fluid"
                    />
                    <span v-else class="white--text headline">{{
                            app.photo != null
                                ? app.photo.toUpperCase()
                                : app.name
                                    ? app.name[0].toUpperCase()
                                    : app.email[0].toUpperCase()
                        }}</span>
                </div>
                <p>{{ app.name ? app.name.toUpperCase() : app.email.toUpperCase() }}</p>
                <a
                        type="button"
                        v-if="data.status === 'Assigned' && data.canEdit"
                        class="flex-shrink-0 ms-1"
                        @click.prevent="deleteUser('Approve', app, a)"
                >
                    <font-awesome-icon :icon="['fas', `trash-alt`]"></font-awesome-icon>
                </a>
                <a
                        type="button"
                        v-if="
            data.status === 'Assigned' &&
            data.canEdit &&
            (app.status == 'Invited' || app.status == 'Re Invited')
          "
                        class="flex-shrink-0 ms-1"
                        @click.prevent="resendInvitation('approver', app, a)"
                >
                    <font-awesome-icon
                            :icon="['fas', `envelope-open`]"
                    ></font-awesome-icon>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import HelperFunction from "@/common/helpers";
    import {reactive, watch} from "vue";

    export default {
        name: "ApproverGroup",
        props: {
            category: {
                type: String,
                required: false,
            },
            author: {
                type: Object,
                required: false,
            },
            reviewer: {
                type: Object,
                required: false,
            },
            approver: {
                type: Object,
                required: false,
            },
            id: {
                type: Number,
                required: false,
            },
            project_id: {
                type: Number,
                required: false,
            },
            status: {
                type: String,
                required: false,
            },
            canEdit: {
                type: Boolean,
                required: false,
            },
            show: {
                type: Array,
                required: false,
            },
            compName: {
                type: String,
                required: false,
            },
        },
        emits: ["buttonSuccess", "approverGroupUpdate"],
        setup(props, {emit}) {
            const {showToast, deleteListData} = HelperFunction();

            let data = reactive({
                author: {
                    id: [],
                    lists: [],
                },
                reviewer: {
                    id: [],
                    lists: [],
                },
                approver: {
                    id: [],
                    lists: [],
                },
                canEdit: false,
                status: "",
                show: [],
            });

            watch(
                () => props,
                (value) => {
                    data.author = value.author;
                    data.reviewer = value.reviewer;
                    data.approver = value.approver;
                    data.canEdit = value.canEdit;
                    data.status = value.status;
                    data.show = value.show;
                },
                {immediate: true, deep: true}
            );

            const approverGroupUpdate = function (name, params) {
                if (typeof params.role === "object" && params.role.length == 0) {
                    showToast({
                        statusText: `Sorry, Set role group in deliverable matrix first!`,
                    });
                    return;
                }

                emit("approverGroupUpdate", name, params);
            };

            const deleteUser = async function (userType, obj, keyVal) {
                let dataStatus = ["Invited", "Re Invited"];

                const param = {
                    type: userType,
                    status: obj.status,
                };

                await deleteListData(
                    `${vueConfig.Approvers.RemoveUser}/${
                        dataStatus.includes(obj.status) ? obj.agmi_id : obj.agm_id
                    }`,
                    param
                ).then((resp) => {
                    emit("buttonSuccess", userType, keyVal, "delete");
                });
            };

            const resendInvitation = async function (userType, obj, keyVal) {
                const data = {
                    type: userType,
                    status: obj.status,
                    id: obj.status == "Invited" ? obj.agmi_id : obj.agm_id,
                };

                alert(data, "not tested yet. this will test on template or project user");

                // storeFormData()

                // this.$store
                //   .dispatch("formRequest", {
                //     formData: data,
                //     action: `${this.siteUrl("/api/doc-invitation-resend")}`,
                //   })
                //   .then((resp) => {
                //     this.$toast.success(resp.message);
                //     this.$emit("buttonSuccess", userType, keyVal, "re-invite");
                //   });
            };

            return {
                data,
                approverGroupUpdate,
                deleteUser,
                resendInvitation,
            }; // anything returned here will be available for the rest of the component
        },
    };
</script>

